import { Vue, Component } from 'nuxt-property-decorator'
import { ConfigItem } from '~/store/modules/config/interfaces'
@Component
export default class ConfigMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  /**
  * * Запрос на сохранение изменений config
  * @returns обьект всех параметров конфига
  **/
  async editConfig () {
    try {
      this.$wait.start('saveChanges')
      const data = await this.$configsList.editConfig()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Конфиг изменен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('saveChanges') }
  }

  /**
  * * Запрос на сохранение изменений
  * @param config - конфиг который мы изменяем
  * возвращает параметр config
  **/
  async editConfigs (config: ConfigItem[]) {
    try {
      this.$wait.start('saveChanges')
      const data = await this.$configsList.editConfigs(config)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Конфиг изменен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('saveChanges') }
  }

  async dropCache () {
    try {
      this.$wait.start('dropCache')
      const data = await this.$configsList.dropCache()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Кеш сброшен'
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('dropCache') }
  }
}
