import { Address } from '../delivery/interfaces'
import { PageParams, Paginator, Rules } from '~/store/interfaces'
import { PromoCode } from '~/store/modules/promo-codes/interfaces'

/**
 * * Статусы оплаты заказа
 */
export enum PAYMENT_STATUSES {
  PAYED = 'Оплачен',
  NOT_PAYED = 'Не оплачен',
  ALL = 'Все'
}

/**
 * * Роуты апи запросов на excel файлы
 */
export enum ExcelType {
  EXCEL = 'excel',
  EXCEL_GD = 'excel-gd',
}

/**
 * * Правила валидации заказа
 */
export interface ValidatorParams {
  email: Rules[];
  phone: Rules[];
  deliveryData: {
    pickupPointAddress: Rules[],
    code: Rules[];
    options: {
      code: Rules[];
      types: {
        name: Rules[];
      };
    };
  };
  bonus: Rules[];
  deliveryMargin: Rules[];
  shipping: {
    address: {
      value: Rules[];
      house: Rules[];
      city: Rules[]
    };
    phone: Rules[];
    email: Rules[];
    otherPhone: Rules[];
  };
}

/**
 * * Шаблон парметров order
 */
export interface Order {
  id: number;
  siteId: number;
  profile: ProfileParams;
  shipping: ShipingParams;
  paymentDiscount: number | null;
  deliveryData: DeliveryParams;
  description?: string;
  optionsTitle?: string;
  options?: OptionsParams;
  paymentId: number;
  comment?: string | null;
  statusId: number;
  statusGroupsId: number;
  email?: string;
  trackId: string | null;
  prices: PriceParams;
  deliveryMargin: number;
  bonus: number;
  freeDeliverySum: number;
  role?: string;
  promoCode?: PromoCode | null;
  promoCodeId?: number | null;
  sync?: Boolean;
  discount?: number[];
  isDeleted?: boolean;
  createdAt?: number | null;
  updatedAt?: number | null;
  orderOffers: OrderOffersParams[];
  withIsBlank?: boolean;
  userId?: number;
  condition: number;
  userOrdersCount?: number;
  // Дополнительные параметры заказа
  params: {
    //  Номер пр.заказа в ТД
    orderNumberTD?: number;
    // Номер пр.плана
    planNumber?: number;
    exactDeliveryDate?: string;
  };
  // Состояние оплаты счетов заказа
  isPayed: boolean;
}

/**
 * * Фильтры
 */
export interface Filters {
  // Номер страницы
  page?: number;
  // Кол-во записей на страницу
  pageSize?: number;
  // Id заказа
  id?: number;
  // Id статуса заказа
  statusId?: string;
  // ФИО пользователя
  fio?: string;
  // Роль пользователя
  role?: string;
  // Id группы статусов заказа
  statusGroupsId?: string;
  // Трэк номер заказа
  track?: number;
  // Электронная почта пользователя
  email?: string;
  // Номер телефона пользователя
  phone?: string;
  // Начало временного промежутка создания заказов
  dateFrom?: number;
  // Конец временного промежутка создания заказов
  dateTo?: number;
  // Id пользователя
  userId?: number;
  // Статус оплаты заказа
  isPayed?: boolean
}
/**
 * * Шаблон парметров profile person в order
 */
export interface ProfileParams {
  userId: number;
  email: string;
  fio?: string;
  phone?: string;
  userRole?: string;
  jointPurchaser?: string;
  orgName?: string;
  inn?: string;
  ogrn?: string;
  kpp?: string;
  rs?: string;
  ks?: string;
  bic?: string;
  bank?: string;
}

/**
 * * Шаблон парметров shiping в order
 */
export interface ShipingParams {
  contactPerson: string;
  email: string;
  phone: string;
  zip: string;
  address: AddressParams;
  isDefault: boolean;
  kladrId: string;
}

/**
 * * Шаблон парметров address of shiping в order
 */
export interface AddressParams {
  city?: string;
  country?: string;
  house?: string;
  region?: string;
  street?: string;
  settlement?: string;
  value: string;
}

/**
 * * Шаблон парметров deliveryData в order
 */
export interface DeliveryParams {
  pickupPointAddress?: string;
  id?: number;
  code: string;
  name?: string;
  price: number;
  description?: string;
  optionsTitle?: string;
  typeTitle?: number | null;
  options: OptionsParams;
  image?: ImageDataParams;
  isDefault: boolean;
  useDefault?: boolean;
  basePrice: number;
  pickupPointCode?: string
}

/**
 * * Шаблон парметров image
 */
export interface ImageDataParams {
  origin?: string;
  blurHash?: string | null;
  fileId?: number | null;
  sizes?: SizesParams;
}

/**
 * * Шаблон парметров imageSizes
 */
export interface SizesParams {
  full?: ImageTypes;
  preview?: ImageTypes;
  thumb?: ImageTypes;
}

/**
 * * Шаблон парметров imageTypes
 */
export interface ImageTypes {
  jpg?: string;
  webp?: string;
}

/**
 * * Шаблон парметров options
 */
export interface OptionsParams {
  id?: number;
  code: string;
  name?: string;
  groupId?: number;
  image?: { fileId: number | null };
  url?: string | null;
  // * Объект адресной информации доставки
  meta?: Address[];
  types: OptionsTypes;
}

/**
 * * Шаблон парметров типов options
 */
export interface OptionsTypes {
  id?: number;
  code: string;
  name?: string;
  price?: number;
  image?: { fileId: number | null };
  basePrice?: number;
  tariffCode?: string
}

/**
 * * Шаблон парметров prices
 */
export interface PriceParams {
  price: number;
  oldPrice: number;
  margin: number;
  promoDiscount: number;
  discountWithSetDiscount: number;
}

/**
 * * Шаблон парметров orderOffers в order
 */
export interface OrderOffersParams {
  id?: number;
  offerId?: number;
  count?: number;
  productctionCount?: number;
  price?: number;
  margin?: number;
  discount?: number[];
  createdAt?: number;
  updatedAt?: number;
  product?: ProductParams;
  isBlank?: boolean;
}

/**
 * * Шаблон парметров product в orderOffers
 */
export interface ProductParams {
  id?: number;
  title?: string;
  code?: string;
  description?: string;
  vendorCode?: string;
  images?: ImageDataParams[];
  categoryId?: number;
  stickerIds?: number[] | null;
  options?: OptionsValues[];
  tags?: number[];
  categories?: CategoriesParams[];
  allRemnants?: number;
  cardPrices?: PriceParams;
  groups?: GroupsParams[];
}

/**
 * * Шаблон парметров options
 */
export interface OptionsValues {
  id?: number;
  title?: string;
  code?: string;
  values?: OptionsValuesParams[];
}

/**
 * * Шаблон парметров values в options в product
 */
export interface OptionsValuesParams {
  id?: number;
  title?: string;
  code?: string;
}

/**
 * * Шаблон парметров categories в product
 */
export interface CategoriesParams {
  id?: number;
  title?: string;
  code?: string;
  parentId?: number | null;
  childrenIds?: number[];
}

/**
 * * Шаблон парметров groups в product
 */
export interface GroupsParams {
  id?: number;
  title?: string;
  offer?: OffersParams[];
}

/**
 * * Шаблон парметров offer в groups в product
 */
export interface OffersParams {
  id?: number;
  barcode?: string;
  vendorCode?: string;
  title?: string;
  description?: string;
  weight?: number;
  len?: number;
  width?: number;
  height?: number;
  groupId?: number;
  options?: OptionsValues[];
  isBlank?: boolean;
  prices?: PriceParams;
  remnants?: number;
}

/**
 * * Шаблон парметров AllPrice
 */
export interface AllPrice {
  price: number;
  full_price: number;
}

/**
 * * Список orders
 */
export interface Orders {
  data: Order[];
  prices: AllPrice;
  paginator: Paginator;
  countNew: number;
}

export interface EditOrderRequestData extends Partial<
  Pick<Order,
    | 'params'
    | 'paymentId'
    | 'promoCodeId'
    | 'siteId'
    | 'statusId'
    | 'statusGroupsId'
    | 'trackId'>
> {
  user?: OrderUser
  shipping?: OrderUser
}

export interface OrderUser {
    email?: string,
    fio?: string
    phone?: string
}

/**
 * * Объект плагина
 */
export interface OrdersPlugin {
  /**
   * * список order и paginator
   */
  ordersList: Orders;

  /**
   * * текущий order
   */
  currentOrder: Order;

  /**
   * * Фильтры
   */
  filters: Filters;

  /**
   * * Валидна ли форма
   */
  validateData: Boolean;

  /**
   * *
   */
  validators: ValidatorParams;

  /**
   * * Запрос на список Orders
   */
  getOrders: (pageParams: PageParams) => Promise<Orders>;

  /**
   * * Поиск order в списке OrdersList по id
   */
  orderById: (id: number) => Order | undefined;

  /**
   * * Обнулить форму order
   */
  resetCurrentOrder: () => void;

  /**
   * * Обнулить ordersList
   */
  resetOrders: () => void;

  /**
   * * Обнулить фильтры
   */
  resetFilters: () => void;

  /**
   * * Запрос order по id
   */
  getOrder: (id: number) => Promise<Order>;

  /**
   * * Запрос на удаление order по id
   */
  removeOrder: (id: number) => Promise<Order>;

  /**
   * * Запрос на изменение order
   */
  editOrder: (id: number, data: EditOrderRequestData) => Promise<void>;

  /**
   * * Запрос на создание order
   */
  createOrder: (userId: number) => Promise<{orderId: number}>;

  /**
   * * Запрос на получение цен order
   */
  getOrderPrice: () => Promise<PriceParams>;

  /**
   * * Запрос на применение скидок к товарам в order
   */
  applyDiscount: (id: number, discount: number) => void;

  /**
   * * Выгрузить в excel
   */
  exportExcel: (
    reqType: ExcelType,
    pageParams?: PageParams | null
  ) => Promise<Blob>;
}
