import { Component } from 'nuxt-property-decorator'
import { Page, StaticPagesParams } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { ValidatorParams } from '~/store/interfaces'

enum UpdateMsgs {
  sites = 'Сайты',
  roles = 'Роли'
}

@Component
export default class StaticPagesMixin extends StoreMixin {
  /**
   * * Data
   */
  treeNodeMap = new Map()
  pagesSort = {} as Record<number, number>

  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$pages.resetItem()
    this.$pages.resetList()
    this.$pages.resetFilters()
    this.$pageTemplates.resetItem()
    this.$pageTemplates.resetList()
    next()
  }

  /**
   * Получение списка страниц
   * @param pageParams - параметры пагинации
   * @param save - флаг сохранения значений в store
   * @param loader - ключ лоадера
   * @returns список страниц
   */
  async getPages (pageParams: StaticPagesParams, save: boolean = true, useFilters: boolean = false, loader: string = 'getPages') {
    return await this.asyncRequestHandler(
      { loader },
      this.$pages.getList(pageParams,
        save,
        useFilters)
    )
  }

  /**
   * * Получение страницы по id
   * @param id - id страницы
   * @param save -флаг сохранения значения в store
   * @returns статическая страница
   */
  async getPage (id: number, save: boolean = true, loader: string = 'getPage') {
    return await this.asyncRequestHandler(
      { loader },
      this.$pages.getItem(id,
        save)

    )
  }

  /**
   * * Создание страницы
   * @returns статическая страница
   */
  async createPage () {
    return await this.asyncRequestHandler(
      { loader: 'createPage' },
      this.$pages.createItem()
    )
  }

  /**
   * * Изменение страницы
   * @param id - id страницы
   * @returns статическая страница
   */
  async editPage (id: number, item?: Page) {
    return await this.asyncRequestHandler(
      { loader: 'editPage' },
      this.$pages.editItem(id, item)

    )
  }

  /**
   * * Удаление страницы
   * @param id - id страницы
   * @returns статическая страница
   */
  async removePage (id: number) {
    return await this.asyncRequestHandler(
      { loader: 'removePage' },
      this.$pages.removeItem(id)

    )
  }

  async updateChildren (api: 'sites' | 'roles', id: number, item?: Page) {
    return await this.asyncRequestHandler(
      { loader: 'updateChildren' },
      (async function (api: 'sites' | 'roles', id: number): Promise<void> {
        // * Предварительный запрос на изменение страницы
        await this.$pages.editItem(id, item)
        // * Запрос на привязку ролей к дочерним страницам
        await this.$pages.updateChildren({ api, id })
        this.$notify({
          type: 'success',
          title: 'Выполнено',
          message: `${UpdateMsgs[api]} дочерних страниц обновлены`
        })
      }(api,
        id))

    )
  }

  validators: ValidatorParams = {
    title: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название способа',
      trigger: ['blur']
    }],
    sort: [{
      pattern: validatorsPattern.wholeNumbers,
      message: 'Введите целое число',
      trigger: ['blur', 'change']
    }],
    page: [{
      pattern: validatorsPattern.naturalNumbers,
      message: 'Введите натуральное число',
      trigger: ['blur', 'change']
    }]
  }
}
